import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import cn from 'classnames';
import { viewerModel } from 'entities/viewer';

export const Header = () => {
    const navLinks = [
        { title: 'Home', to: '/' },
        // { title: 'Stats', to: '/stats' },
        // { title: 'Scaling', to: '/scaling' },
    ];

    return (
        <header className={'bg-zinc-900 text-white p-5 flex items-center gap-10'}>
            <Link to={'/'}>
                <span className="font-black text-2xl tracking-tighter">{'> S.P.N.'}</span>
            </Link>
            <ul className={'flex gap-5 font-semibold'}>
                {navLinks.map(({ title, to }) => (
                    <li key={title}>
                        <NavLink
                            to={to}
                            className={({ isActive }) =>
                                cn({ underline: isActive }, 'decoration-2 underline-offset-4 py-3 uppercase')
                            }
                        >
                            {title}
                        </NavLink>
                    </li>
                ))}
            </ul>
            <button className="ml-auto font-semibold uppercase" onClick={() => viewerModel.logout()}>
                / exit
            </button>
        </header>
    );
};
