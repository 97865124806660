import React, { useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
} from 'chart.js';
import _ from 'lodash';
import { Line } from 'react-chartjs-2';

import { Header, Main } from 'widgets';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DISPLAY = true;
const BORDER = true;
const CHART_AREA = true;
const TICKS = true;

export const options = {
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            // display: false,
            grid: {
                color: 'rgba(255, 255, 255, 0.3)',
            },
        },
        y: {
            // display: false,
            grid: {
                color: 'rgba(255, 255, 255, 0.3)',
            },
        },
    },
};

function randomDate(start: Date, end: Date) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}

const getMockDate = () => randomDate(new Date('2022-05-16T00:00:00'), new Date());
const getRandomUsage = () => getRandomInt(20);

const MOCK_CREDITS = new Array(100)
    .fill(null)
    .map(() => ({ date: getMockDate().toISOString(), requestCount: getRandomUsage() }))
    // @ts-ignore
    .sort((a: any, b: any) => new Date(a.date) - new Date(b.date));

export const CreditsUsageTable = () => {
    const [requestsData, setRequestsData] = useState(MOCK_CREDITS);

    const labels = _.map(requestsData, 'date');
    const creditsByDate = _.mapValues(_.keyBy(requestsData, 'date'), 'requestCount');

    const data = {
        labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        datasets: [
            // {
            //     label: 'Front',
            //     borderColor: 'rgb(59, 130, 246, 0.7)',
            //     backgroundColor: 'rgba(59, 130, 246, 1)',
            //     data: [3400, 3000, 2500, 4500, 2500, 3400, 3000],
            // },
            // {
            //     label: 'Middle',
            //     borderColor: 'rgba(255, 172, 100, 0.8)',
            //     backgroundColor: 'rgba(255, 172, 100, 1)',
            //     data: [1900, 1700, 2100, 3600, 2200, 2500, 2000],
            // },
            {
                label: 'Back',
                borderColor: 'rgba(88, 188, 116, 0.8)',
                backgroundColor: 'rgba(88, 188, 116, 1)',
                data: [1000, 1400, 1100, 2600, 2000, 900, 1400],
            },
        ],
    };

    return (
        // <div className="h-full flex flex-col">
        //     <div className="flex">
        //         <button>1m</button>
        //         <button>5m</button>
        //         <button>30m</button>
        //         <button>1h</button>
        //         <button>12h</button>
        //     </div>
        //
        //     <Line className="max-h-[90%] mt-auto" options={options} data={data} />
        // </div>

        <Line className="max-h-[90%] mt-auto" options={options} data={data} />
    );
};

const Index = () => {
    return (
        <>
            <Header />
            <Main className="bg-black p-10">
                <CreditsUsageTable />
            </Main>
        </>
    );
};

export default Index;
