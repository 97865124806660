import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { viewerModel } from 'entities/viewer';
import { useStore } from 'effector-react';

import Stats from './stats';
import Home from './home';
import Auth from './auth';
import Scaling from './scaling';

export const Router = () => {
    const isAuth = useStore(viewerModel.$isAuth);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={isAuth ? <Outlet /> : <Navigate to="/auth" />}>
                    <Route index element={<Home />} />
                    <Route path="stats" element={<Stats />} />
                    <Route path="scaling" element={<Scaling />} />
                </Route>
                <Route path="auth" element={!isAuth ? <Auth /> : <Navigate to="/" />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </BrowserRouter>
    );
};
