import { viewerModel } from 'entities/viewer';
import React, { FormEvent, ChangeEvent, useState } from 'react';
import { useStore } from 'effector-react';

const Index = () => {
    const isLoading = useStore(viewerModel.loginFx.pending);
    const [error, setError] = useState<boolean>(false);
    const [password, setPassword] = useState('');

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        viewerModel.loginFx(password).catch(() => setError(true));
    };

    const handlePasswordInput = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        setError(false);
    };

    return (
        <div className="bg-black h-full w-full grid place-content-center">
            <form className={'text-white font-bold text-3xl'} onSubmit={onSubmit}>
                <span>{!error && isLoading && '/'}</span>
                <span>{!error && !isLoading && '>'}</span>
                <span className="text-red-600">{error && '×'}</span>
                <input
                    className="outline-0 bg-transparent ml-3"
                    placeholder="•••••••••••••••••••••••••••"
                    type="password"
                    value={password}
                    onChange={handlePasswordInput}
                    ref={(input) => input && input.focus()}
                />
            </form>
        </div>
    );
};

export default Index;
