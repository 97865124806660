import axios from 'axios';
import { createServer, Response } from 'miragejs';

createServer({
    routes() {
        this.post('/login', (_, request) => {
            let { password } = JSON.parse(request.requestBody);

            if (password !== 'password') {
                return new Response(400, { some: 'header' }, { errors: ['name cannot be blank'] });
            }

            return new Response(200);
        });
    },
});

export function login(password: string): Promise<string> {
    return axios.post('/login', { password });
}
