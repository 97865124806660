import React, { FC, ReactNode, InputHTMLAttributes, useState, LabelHTMLAttributes } from 'react';
import { Header, Main } from 'widgets';
import { Listbox } from '@headlessui/react';
import { CreditsUsageTable } from '../stats';

const countries = [
    { id: 1, name: 'FR' },
    { id: 2, name: 'EN' },
    { id: 3, name: 'RU' },
];

const regions = [
    { id: 1, name: 'fr-west-1' },
    { id: 2, name: 'fr-east-1' },
];

const providers = [
    { id: 1, name: 'google' },
    { id: 2, name: 'amazon' },
    { id: 3, name: 'mail.ru' },
];

const metrics = [
    { id: 1, name: 'google' },
    { id: 2, name: 'amazon' },
    { id: 3, name: 'mail.ru' },
];

type Option = {
    id: number;
    name: string;
};

const Label: FC<LabelHTMLAttributes<HTMLLabelElement>> = ({ children }) => {
    return <span className="text-xs font-medium mb-2 block">{children}</span>;
};

const Select: FC<{ options: Option[]; placeholder: string; label?: string }> = ({ options, placeholder, label }) => {
    const [selected, setSelected] = useState<Option | null>();

    return (
        <label className="text-sm">
            {label && <Label>{label}</Label>}
            <Listbox value={selected} onChange={setSelected}>
                <div className={'relative'}>
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left border-2 border-gray-300 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
                        <span>{selected ? selected.name : placeholder}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">+</span>
                    </Listbox.Button>

                    <Listbox.Options className="absolute top-8 z-10 left-0 right-0 drop-shadow-md bg-white py-2 px-3 mt-2">
                        {options.map((option: Option) => (
                            <Listbox.Option className="cursor-pointer" key={option.id} value={option}>
                                {option.name}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </div>
            </Listbox>
        </label>
    );
};

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
}

const Input: FC<InputProps> = ({ label, ...rest }) => {
    return (
        <label>
            {label && <Label>{label}</Label>}
            <input className="py-2 pl-3 sm:text-sm border-2 border-gray-300 w-full" {...rest} />
        </label>
    );
};

const ScalingForm = () => {
    return (
        <div className="p-5 border-2 border-gray-300 flex flex-col gap-5">
            <Select options={countries} label="Country" placeholder="Choose country" />
            <Select options={regions} label="Region" placeholder="Choose regions" />
            <Select options={providers} label="Provider" placeholder="Choose provider" />

            <div className="grid grid-cols-2 gap-3">
                <Input label="Min" type="text" placeholder="count" defaultValue="0" />
                <Input label="Max" type="text" placeholder="count" defaultValue="10" />
            </div>
            <hr />
            <div className="flex flex-col gap-5">
                <Input label="Response time" type="text" placeholder="ms" />
                <Input label="Response count" type="text" placeholder="count" />

                {/*<MyListbox options={metrics} placeholder="Choose metric" />*/}
                {/*<div className="grid grid-cols-2 gap-3">*/}
                {/*    <input type="text" className="py-2 pl-3 sm:text-sm border-2 border-gray-300" placeholder="target" />*/}
                {/*    <input type="text" className="py-2 pl-3 sm:text-sm border-2 border-gray-300" placeholder="unit" />*/}
                {/*</div>*/}
            </div>

            <button className="sm:text-sm w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                Create
            </button>
        </div>
    );
};

const Index = () => {
    return (
        <>
            <Header />
            <Main className="p-5">
                <div className="grid grid-cols-5 gap-10">
                    <ScalingForm />
                </div>
            </Main>
        </>
    );
};

export default Index;
