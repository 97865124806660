import React, { FC, ChangeEvent, useState, useRef, useEffect, useCallback } from 'react';
import { Header, Main } from 'widgets';
import _ from 'lodash';
import cn from 'classnames';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import {
    Column,
    Table,
    ColumnFiltersState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    getFacetedUniqueValues,
    FilterFn,
} from '@tanstack/react-table';
import { timeLib } from 'shared/lib';

type Proxy = {
    id?: string;
    ip: string;
    geolocation: {
        countryCode: string;
        regionName: string;
        isp: string;
    };
    createdAt: string | number;
    responseTime: string | number;
    updatedAt: string | number;
};

// const getMockProxies = () => {
//     return MockProxy.map((proxy) => ({
//         id: '',
//         ip: proxy.ip,
//         geolocation: {
//             countryCode: proxy.country,
//             regionName: proxy.city,
//             isp: proxy.org,
//         },
//         responseTime: proxy.responseTime,
//         updatedAt: proxy.updated_at,
//         createdAt: proxy.upTime,
//     }));
// };

const Filter: FC<{ column: Column<any, unknown>; table: Table<any> }> = ({ column, table }) => {
    const colId = column.id;
    const colUniqueValues = column.getFacetedUniqueValues();
    const selectedOptions = (column.getFilterValue() as string[]) || [];
    const tableFiltersState = table.getState().columnFilters;
    const allRows = table.getCoreRowModel().rows;

    const enabledOptions = React.useMemo(() => {
        const otherFilters = tableFiltersState.filter((filter) => filter.id !== colId);

        const enabledOptions = allRows
            .filter((row) =>
                otherFilters.every(
                    (f) => _.isArray(f.value) && (f.value.length === 0 || f.value.includes(row.getValue(f.id)))
                )
            )
            .map((row) => row.getValue(colId));

        return new Set(enabledOptions);
    }, [tableFiltersState, colId, allRows]);

    const options = React.useMemo(() => [...colUniqueValues.keys()].sort(), [colUniqueValues]);

    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedOptions = _.map([...e.target.selectedOptions], 'value').filter(Boolean);
        column.setFilterValue(selectedOptions);
    };

    const handleSelectReset = () => column.setFilterValue([]);

    const isOptionDisabled = (option: string) => {
        return !enabledOptions.has(option) && !selectedOptions.includes(option);
    };

    return (
        <MultiSelect
            isOptionDisabled={isOptionDisabled}
            options={options}
            selectedOptions={selectedOptions}
            label={column.columnDef.id}
            onChange={handleSelectChange}
            onReset={handleSelectReset}
        />
    );
};

const MultiSelect: FC<{
    label: any;
    options: string[];
    selectedOptions: string[];
    isOptionDisabled: (v: string) => boolean;
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    onReset: () => void;
}> = ({ label, options, selectedOptions, isOptionDisabled, onChange, onReset }) => {
    const selectRef = useRef<HTMLSelectElement>(null);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
        if (!selectRef.current) return;
        selectRef.current.scrollTo(0, 0);
    };

    return (
        <div>
            <legend className="flex w-full">
                <span className="font-bold uppercase">
                    {label} {selectedOptions.length !== 0 && `(${selectedOptions.length})`}
                </span>

                {selectedOptions.length !== 0 && (
                    <button className="ml-auto text-xs" onClick={onReset}>
                        reset all
                    </button>
                )}
            </legend>

            <select
                ref={selectRef}
                className={cn(
                    { 'h-[238px]': isExpanded },
                    'w-full bg-transparent outline-0 scrollbar-thin scrollbar-thumb-rounded-md scrollbar-thumb-zinc-600 scrollbar-track-zinc-800 overflow-x-hidden pr-5 my-2'
                )}
                value={selectedOptions}
                onChange={onChange}
                multiple
            >
                {options.map((option, i) => (
                    <option
                        className={cn(
                            'py-1 bg-transparent focus:bg-transparent checked:bg-transparent checked:text-white before:mr-2.5 text-sm max-w-full truncate',
                            selectedOptions.includes(option) ? "before:content-['[1]']" : `before:content-['[0]']`
                        )}
                        disabled={isOptionDisabled(option)}
                        key={option}
                        value={option}
                    >
                        {option}
                    </option>
                ))}
            </select>

            {options.length > 4 && (
                <button className="text-xs flex items-center" onClick={handleExpandClick}>
                    {!isExpanded ? (
                        <>
                            {options.length - 4} more <ChevronDownIcon className="ml-1 inline-block w-3 h-3" />
                        </>
                    ) : (
                        <>
                            Hide <ChevronUpIcon className="ml-1 inline-block w-3 h-3" />
                        </>
                    )}
                </button>
            )}
        </div>
    );
};

const ClientsStoreAction = {
    CREATED: 'CREATED',
    REMOVED: 'REMOVED',
    UPDATED: 'UPDATED',
};

const columnHelper = createColumnHelper<Proxy>();

const proxiesFilter: FilterFn<any> = (row, columnId, value) => {
    return value.length === 0 || value.includes(row.getValue(columnId));
};

const columns = [
    columnHelper.accessor('ip', {
        cell: (info) => info.getValue(),
        enableSorting: false,
    }),
    columnHelper.accessor('geolocation.countryCode', {
        id: 'country',
        filterFn: proxiesFilter,
        header: 'country',
        enableSorting: false,
        cell: (info) => (
            <div className="flex gap-2 items-center">
                <img
                    className="w-5"
                    src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${info.getValue()}.svg`}
                    alt={info.getValue()}
                    title={info.getValue()}
                />
                <span>{info.getValue()}</span>
            </div>
        ),
    }),
    columnHelper.accessor('geolocation.regionName', {
        id: 'region',
        filterFn: proxiesFilter,
        header: () => 'region',
        enableSorting: false,
    }),
    columnHelper.accessor('geolocation.isp', {
        id: 'provider',
        filterFn: proxiesFilter,
        header: 'provider',
        enableSorting: false,
        cell: (info) => (
            <div className="truncate" title={info.getValue()}>
                {info.getValue()}
            </div>
        ),
    }),
    columnHelper.accessor('createdAt', {
        header: 'uptime',
        cell: (info) => (
            <span className="animate-colorPulse">{timeLib.convertMsToTime(Date.now() - Number(info.getValue()))}</span>
        ),
    }),
    columnHelper.accessor('responseTime', {
        header: 'response ms',
        id: 'responseTime',
        cell: (info) => (
            <span key={info.getValue()} className="animate-colorPulse">
                {info.getValue()}
            </span>
        ),
    }),
    columnHelper.accessor('updatedAt', {
        header: 'updated',
        cell: (info) => (
            <span key={info.getValue()} className="animate-colorPulse">
                {new Date(info.getValue()).toLocaleTimeString()}
            </span>
        ),
    }),
];

const ProxiesList: FC = () => {
    const ws = useRef<WebSocket | null>(null);
    const [data, setData] = useState([]);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
        {
            id: 'region',
            value: [],
        },
        {
            id: 'country',
            value: [],
        },
        {
            id: 'provider',
            value: [],
        },
    ]);

    const gettingData = useCallback(() => {
        if (!ws.current) return;

        ws.current.onmessage = (e) => {
            try {
                const message = JSON.parse(e.data);
                const { action, values } = message;
                console.log(`WS: ${JSON.stringify(message)}`);

                switch (action) {
                    case ClientsStoreAction.CREATED:
                        setData((prev) => _.union(prev, values));
                        break;

                    case ClientsStoreAction.REMOVED:
                        setData((prev) => {
                            const updated = prev.filter((v: any) => !_.map(values, 'id').includes(v.id));
                            console.log({ prev, updated });
                            return updated;
                        });
                        break;

                    case ClientsStoreAction.UPDATED:
                        setData((prev) => {
                            const updated = _.map(prev, (v: any) =>
                                _.map(values, 'id').includes(v.id) ? _.find(values, { id: v.id }) : v
                            );
                            console.log({ prev, updated, values });
                            return updated as [];
                        });
                        break;

                    default:
                        setData(values);
                }
            } catch (e) {
                console.log(`catch ${e}`);
            }
        };
    }, []);

    useEffect(() => {
        if (ws.current !== null) return;
        ws.current = new WebSocket(process.env.REACT_APP_PROXY_CLIENTS_URL as string);
        gettingData();
    }, [ws, gettingData]);

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            columnFilters,
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });

    if (_.isEmpty(data)) {
        return <div className="h-full flex items-center justify-center" />;
    }

    return (
        <div className="grid grid-cols-[300px_1fr]">
            <div className="sticky top-5 h-fit flex overflow-y-auto flex-col gap-6 pr-5 border-black">
                <Filter column={table.getColumn('region')} table={table} />
                <Filter column={table.getColumn('provider')} table={table} />
                <Filter column={table.getColumn('country')} table={table} />
            </div>
            <table className="text-left table-fixed text-xs h-fit w-full">
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th key={header.id} className="select-none uppercase truncate px-5">
                                    <div
                                        {...{
                                            className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                                            onClick: header.column.getToggleSortingHandler(),
                                        }}
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {{ asc: ' ▲', desc: ' ▼' }[header.column.getIsSorted() as string] ?? null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id} className="even:bg-zinc-800 text-zinc-400 animate-[pulse_1s_ease-in-out]">
                            {row.getVisibleCells().map((cell) => (
                                <td key={cell.id} className="truncate py-3 px-5">
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="h-4" />
        </div>
    );
};

const Index = () => {
    return (
        <>
            <Header />
            <Main className="bg-zinc-900 text-white p-5 font-medium">
                <ProxiesList />
            </Main>
        </>
    );
};

export default Index;
