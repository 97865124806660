import { createEffect, createEvent, createStore, forward } from 'effector';
import { authApi } from 'shared/api';

export const loginFx = createEffect(authApi.login);

export const login = createEvent();
export const logout = createEvent();

export const $isAuth = createStore(true)
    .on(login, () => true)
    .on(logout, () => false);

forward({ from: loginFx.done, to: login });
